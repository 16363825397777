import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Switch from '@mui/material/Switch';

Modal.setAppElement('#root'); // this line is very important to avoid certain issues




import { DeflyWalletConnect } from "@blockshake/defly-connect";
import { PeraWalletConnect } from "@perawallet/connect";
import algosdk from "algosdk";

const peraWallet = new PeraWalletConnect();
const deflyWallet = new DeflyWalletConnect();






const token = '';
const server = 'https://mainnet-api.algonode.cloud';
const port = 443;
const algodClient = new algosdk.Algodv2(token, server, port);

const APPLICATION_ID_PROXY = 233725848;
const APPLICATION_ID_STAKING = 233725850;
const ASSET_ID = 226701642;
const ESCROW_ADDR = "FMBXOFAQCSAD4UWU4Q7IX5AV4FRV6AKURJQYGXLW3CTPTQ7XBX6MALMSPY";
const ESCROW_PROGRAM_STR = "AiAGAgaYv7lvAAUBMgQiDzIEIw4QQQAuMwAYJBJAAANCACMzABAjEjMAGSUSIQQzABkSERAzASAyAxIQMwAgMgMSEEAAAiVDIQVD";

export default function App() {
  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
  }, []);

 

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [peraAccountAddress, setPeraAccountAddress] = useState(null);
  const [deflyAccountAddress, setDeflyAccountAddress] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [stakeAmount, setStakeAmount] = useState("");


  const isConnectedToPeraWallet = !!peraAccountAddress;
  const isConnectedToDeflyWallet = !!deflyAccountAddress;
  
  const [stakedYieldly, setStakedYieldly] = useState(0);
  const [availableYieldly, setAvailableYieldly] = useState(0);
  const [operationMode, setOperationMode] = useState('withdraw');

  
    <Switch
      checked={operationMode === 'stake'}
      onChange={(e) => setOperationMode(e.target.checked ? 'stake' : 'withdraw')}
      color="primary"
      name="operationModeSwitch"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  0

  


// this hook will run every time peraAccountAddress or deflyAccountAddress changes
useEffect(() => {
    const fetchStakedYieldly = async () => {
        const connectedAddress = isConnectedToPeraWallet
        ? peraAccountAddress
        : deflyAccountAddress;

        if (!connectedAddress) return; // return early if no wallet is connected

        const res = await fetch(`https://mainnet-idx.algonode.cloud/v2/accounts/${connectedAddress}/apps-local-state?application-id=233725850`);
        const data = await res.json();
        
        // Find the value for the key "VUE="
        const valueEntry = data["apps-local-states"][0]["key-value"].find(entry => entry.key === "VUE=");

        if (valueEntry) {
          // Convert from microyieldly to yieldly
          setStakedYieldly(valueEntry.value.uint / 1000000);
        }
    };


    const fetchAvailableYieldly = async () => {
      const connectedAddress = isConnectedToPeraWallet
          ? peraAccountAddress
          : deflyAccountAddress;
  
      if (!connectedAddress) return; // return early if no wallet is connected
  
      const res = await fetch(`https://mainnet-api.algonode.cloud/v2/accounts/${connectedAddress}/assets/226701642?format=json`);
      const data = await res.json();
  
      // Check if the 'asset-holding' field exists
      if (data['asset-holding']) {
          // Convert microYieldly to Yieldly by dividing by 1,000,000.
          const yieldly = data['asset-holding'].amount / 1000000;
          setAvailableYieldly(yieldly);
      }
  };
  

    fetchStakedYieldly();
    fetchAvailableYieldly();
}, [peraAccountAddress, deflyAccountAddress, isConnectedToPeraWallet]);

  useEffect(() => {
    peraWallet
      .reconnectSession()
      .then((accounts) => {
        peraWallet.connector.on("disconnect", handleDisconnectWalletClick);

        if (accounts.length) {
          setAccountAddress(accounts[0]);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const duration = 9000;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};


  const appStyle = {

    
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#09121c",
    fontFamily: "'Roboto', sans-serif",
  };

  const buttonStyle = {
    marginTop: "2em",
    marginBottom: "2em",
    padding: "1.5em",
    width: "250px",
    fontSize: "13px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#f9558f",
    color: "white",
    cursor: "pointer",
  };

  const inputStyle = {
    marginTop: "1.75em",
    marginBottom: "2em",
    padding: "1em",
    width: "187px",
    fontSize: "13px",
    borderRadius: "5px",
    border: "2px solid #f9558f",
  };

  const labelStyle = {
    color: "white",
    fontSize: "20px",
    fontWeight: "bold",
  };

  const label2Style = {
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "0em",
  };

  const label3Style = {
    color: "white",
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "0.75em",
  };

  const label4Style = {
    color: "white",
    fontSize: "15px",
    fontWeight: "bold",
    marginTop: "0em",
    marginBottom: "0.75em",
  };

  const titleStyle = {
    color: "yellow",
    fontSize: "30px",
    fontWeight: "bold",
    marginBottom: "0.3em",
    marginTop: "0em",
  };

  const paragraphStyle = {
    color: "white",
    fontSize: "14px",
    textAlign: "center",
    lineHeight: "1.6", // this line is added
    width: "70%",
    marginBottom: "2em",
  };

  const paragraph2Style = {
    color: "white",
    fontSize: "14px",
    textAlign: "center",
    width: "70%",
    marginBottom: "0em",
    marginTop: "0.6em",
  };

  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(35, 35, 35, 0.75)',
      transition: '9000ms'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#09121c',
        border: 'none',
        borderRadius: '15px',
        padding: '30px',
        fontFamily: "'Roboto', sans-serif", // using the Roboto font
    }
};

const modalButtonStyle = {
    ...buttonStyle, 
    marginTop: "11px",
    marginBottom: "11px"
};

const modalTitleStyle = {
    ...titleStyle,
    fontSize: '20px',
    color: '#ffffff',
    marginBottom: '20px',
    fontFamily: "'Roboto', sans-serif", // using the Roboto font
};

  function handleOpenModal () {
    setModalIsOpen(true);
  }

  function handleCloseModal () {
    setModalIsOpen(false);
  }

  useEffect(() => {
    if(peraAccountAddress || deflyAccountAddress) {
      setModalIsOpen(false);
    }
  }, [peraAccountAddress, deflyAccountAddress]);

  return (
      <div style={appStyle}>
        
        <div style={titleStyle}>YLDY RESCUE TOOL</div>
      
        <p style={paragraphStyle}>
         The yieldly dapp went unusable again with alot of funds stuck in it, and they have failed to migrate after their deadline of 48 hours.
         This is a tool to get your funds out of the staking contract.
  
         Special thanks to @joshlmao for debugging.
  
        </p>
        


        
        <div style={label4Style}>Operation mode:</div>


<div style={{ display: 'flex', alignItems: 'center' }}>
  <div style={label2Style}>Withdraw</div>
  <Switch
  
    checked={operationMode === 'degenerate'}
    onChange={(e) => setOperationMode(e.target.checked ? 'degenerate' : 'withdraw')}
    color="primary"
    name="operationModeSwitch"
    inputProps={{ 'aria-label': 'primary checkbox' }}
  />
  <div style={label2Style}>Degenerate</div>
</div>

      
 

  
        <div style={label3Style}>Step 1: Connect wallet</div>
        <button
  style={buttonStyle}
  onClick={
    isConnectedToPeraWallet
      ? handleDisconnectPeraWalletClick
      : isConnectedToDeflyWallet
        ? handleDisconnectDeflyWalletClick
        : handleOpenModal
  }
>
  {isConnectedToPeraWallet
    ? "Disconnect Pera Wallet"
    : isConnectedToDeflyWallet
      ? "Disconnect Defly Wallet"
      : "Connect Wallet"
  }
</button>

     
    <Modal 
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      style={{
        overlay: {
          ...defaultStyle,
          backgroundColor: 'rgba(35, 35, 35, 0.75)',
        },
        ...modalStyle
      }}
    >
      <div style={modalTitleStyle}>Choose Wallet</div>

      <button
        style={modalButtonStyle}
        onClick={
          isConnectedToPeraWallet
            ? handleDisconnectPeraWalletClick
            : handleConnectPeraWalletClick
        }
      >
        {isConnectedToPeraWallet ? "Disconnect Pera Wallet" : "Connect to Pera Wallet"}
      </button>

      <button
        style={modalButtonStyle}
        onClick={
          isConnectedToDeflyWallet
            ? handleDisconnectDeflyWalletClick
            : handleConnectDeflyWalletClick
        }
      >
        {isConnectedToDeflyWallet ? "Disconnect Defly Wallet" : "Connect to Defly Wallet"}
      </button>
      
    </Modal>
    
      
      {operationMode === 'withdraw' ? (
        <>
          <div style={labelStyle}>Step 2: Enter the amount</div>
          { (isConnectedToPeraWallet || isConnectedToDeflyWallet) && (
              <p style={paragraph2Style}>
                  {`Yieldly staked: ${stakedYieldly}`}
              </p>
          )}
          <div style={{ position: 'relative', display: 'inline-block' }}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
      <input
        style={{
          ...inputStyle,
          paddingRight: '50px', // Make room for the button
        }}
        type="number"
        value={withdrawAmount}
        onChange={(e) => {
          if (Number(e.target.value) <= stakedYieldly) {
            setWithdrawAmount(e.target.value)
          }
        }}
        placeholder="Enter withdrawal amount"
      />
      <button
        onClick={() => setWithdrawAmount(stakedYieldly)}
        style={{
          position: 'absolute',
          right: '5px',
          bot: '50%',
          top: '49%',
          transform: 'translateY(-50%)',
          // Customize the button as you like
        }}
        disabled={stakedYieldly === 0}
      >
        Max
      </button>
    </div>
          </div>
        
          <div style={labelStyle}>Step 3: Click and sign</div>
          <button
            style={buttonStyle}
            onClick={handleWithdrawOperation}
            disabled={
              !(isConnectedToPeraWallet || isConnectedToDeflyWallet) || 
              withdrawAmount === "" || 
              stakedYieldly === 0 || 
              withdrawAmount === "0"
            }
          >
            Withdraw YLDY
          </button>
        </>
      ) : (
        <>
          <div style={labelStyle}>Step 2: Enter the amount</div>
          { (isConnectedToPeraWallet || isConnectedToDeflyWallet) && (
              <p style={paragraph2Style}>
                  {`Available Yieldly: ${availableYieldly}`}
              </p>
          )}
          <div style={{ position: 'relative', display: 'inline-block' }}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
      <input
        style={{
          ...inputStyle,
          paddingRight: '50px', // Make room for the button
        }}
        type="number"
        value={stakeAmount}
        onChange={(e) => {
          if (Number(e.target.value) <= availableYieldly) {
            setStakeAmount(e.target.value)
          }
        }}
        placeholder="Enter staking amount"
      />
      <button
        onClick={() => setStakeAmount(availableYieldly)}
        style={{
          position: 'absolute',
          right: '5px',
          bot: '50%',
          top: '49%',
          transform: 'translateY(-50%)',
          // Customize the button as you like
        }}
        disabled={availableYieldly === 0}
      >
        Max
      </button>
    </div>
          </div>
        
          <div style={labelStyle}>Step 3: Click and sign</div>
          <button
            style={buttonStyle}
            onClick={handleStakeOperation}
            disabled={
              !(isConnectedToPeraWallet || isConnectedToDeflyWallet) || 
              stakeAmount === "" || 
              stakeAmount === "0"
            }
          >
            Stake YLDY
          </button>
  </>
)}

  
        <ToastContainer />
      </div>
    );
  

    function handleConnectPeraWalletClick() {
      peraWallet
        .connect()
        .then((newAccounts) => {
          peraWallet.connector.on("disconnect", handleDisconnectPeraWalletClick);
    
          setPeraAccountAddress(newAccounts[0]);
        })
        .catch((error) => {
          if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
            console.log(error);
          }
        });
    }
    
    function handleDisconnectPeraWalletClick() {
      peraWallet.disconnect();
    
      setPeraAccountAddress(null);
    }
    
    function handleConnectDeflyWalletClick() {
      deflyWallet
        .connect()
        .then((newAccounts) => {
          deflyWallet.connector.on("disconnect", handleDisconnectDeflyWalletClick);
    
          setDeflyAccountAddress(newAccounts[0]);
        })
        .catch((error) => {
          if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
            console.log(error);
          }
        });
    }

    
    
    function handleDisconnectDeflyWalletClick() {
      deflyWallet.disconnect();
    
      setDeflyAccountAddress(null);
    }

    async function handleWithdrawOperation() {
      if (!isConnectedToPeraWallet && !isConnectedToDeflyWallet) {
        console.log("Not connected to Pera or Defly wallet");
        toast.error('Not connected to Pera or Defly wallet', {
          position: toast.POSITION.BOTTOM_RIGHT
        });

        
        return;
      }
    const amount = parseFloat(withdrawAmount) * 1000000; // Amount to be withdrawn
  
    const params = await algodClient.getTransactionParams().do();

    const connectedAddress = isConnectedToPeraWallet
    ? peraAccountAddress
    : deflyAccountAddress;
  
    console.log('Genesis hash: ', params.genesisHash);
  
    // proxy
    const callProxyTxn = algosdk.makeApplicationNoOpTxn(
      connectedAddress,
      params,
      APPLICATION_ID_PROXY,
      [new Uint8Array(Buffer.from("check"))],
    );
  
    // withdraw operation
    const callStakingTxn = algosdk.makeApplicationNoOpTxn(
      connectedAddress,
      params,
      APPLICATION_ID_STAKING,
      [new Uint8Array(Buffer.from("W"))],
      [ESCROW_ADDR],
    );
  
    // yldy from escrow to user
    const transferTxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
      ESCROW_ADDR,
      connectedAddress,
      undefined,
      undefined,
      amount,
      undefined,
      ASSET_ID,
      params
    );
  
    // pay txn fee
    const payFeeTxn = algosdk.makePaymentTxnWithSuggestedParams(
      connectedAddress,
      ESCROW_ADDR,
      1000,
      undefined,
      undefined,
      params
    );
  
    let txns = [callProxyTxn, callStakingTxn, transferTxn, payFeeTxn];
    algosdk.assignGroupID(txns);
  
    const txnGroup = [[
      { txn: callProxyTxn, signers: [connectedAddress] },
      { txn: callStakingTxn, signers: [connectedAddress] },
      { txn: transferTxn, signers: [] },
      { txn: payFeeTxn, signers: [connectedAddress] },
    ]];
  
    try {
      let signedTxns;
      if (isConnectedToPeraWallet) {
        signedTxns = await peraWallet.signTransaction(txnGroup);
      } else if (isConnectedToDeflyWallet) {
        signedTxns = await deflyWallet.signTransaction(txnGroup);
      }
  
      const program = new Uint8Array(Buffer.from(ESCROW_PROGRAM_STR, 'base64'));
      const lsig = new algosdk.LogicSigAccount(program);
      const logicSigTxn = algosdk.signLogicSigTransactionObject(transferTxn, lsig);
  
      signedTxns.push(logicSigTxn.blob);
      const finalSignedTxns = [ signedTxns[0], signedTxns[1], logicSigTxn.blob, signedTxns[2] ];
  
      const resp = await algodClient.sendRawTransaction(finalSignedTxns).do();
      if (!resp) {
        console.error("oopsie");
        toast.error('Transaction Failed', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
      else {
        console.log("should be success?");
        toast.success('That probably worked!!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch(error) {
      console.log("Error sending transactions: ", error);
      toast.error('Transaction Failed', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }
  async function handleStakeOperation() {
    if (!isConnectedToPeraWallet && !isConnectedToDeflyWallet) {
      console.log("Not connected to Pera or Defly wallet");
      toast.error('Not connected to Pera or Defly wallet', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      return;
    }
  
    const stake = parseFloat(stakeAmount) * 1000000; // Amount to be staked
    const params = await algodClient.getTransactionParams().do();
    const connectedAddress = isConnectedToPeraWallet
      ? peraAccountAddress
      : deflyAccountAddress;
  
    console.log('Genesis hash: ', params.genesisHash);
  
    // Proxy check
    const callProxyTxn = algosdk.makeApplicationNoOpTxn(
      connectedAddress,
      params,
      APPLICATION_ID_PROXY,
      [new Uint8Array(Buffer.from("check"))],
    );
  
    // Stake operation
    const callStakingTxn = algosdk.makeApplicationNoOpTxn(
      connectedAddress,
      params,
      APPLICATION_ID_STAKING,
      [new Uint8Array(Buffer.from("S"))],
      [ESCROW_ADDR],
    );
  
    // YLDY from user to Escrow
    const transferTxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
      connectedAddress,
      ESCROW_ADDR,
      undefined,
      undefined,
      stake,
      undefined,
      ASSET_ID,
      params,
    );
  
    let txns = [callProxyTxn, callStakingTxn, transferTxn];
    algosdk.assignGroupID(txns);
  
    const txnGroup = [
      { txn: callProxyTxn, signers: [connectedAddress] },
      { txn: callStakingTxn, signers: [connectedAddress] },
      { txn: transferTxn, signers: [connectedAddress] },
    ];
  
    try {
      let signedTxns;
if (isConnectedToPeraWallet) {
  signedTxns = await peraWallet.signTransaction([txnGroup]);
} else if (isConnectedToDeflyWallet) {
  signedTxns = await deflyWallet.signTransaction([txnGroup]);
}

  
      const resp = await algodClient.sendRawTransaction(signedTxns).do();
  
      if (!resp) {
        console.error("oopsie");
        toast.error('Transaction Failed', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        console.log("should be success?");
        toast.success('That probably worked!!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch(error) {
      console.log("Error sending transactions: ", error);
      toast.error('Transaction Failed', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }
  
}  
